<template>
  <div>
    <el-row>
      <h3>{{ $t("title.orderinfo") }}</h3>
      <el-form ref="creatermaForm" :model="creatermaForm" :rules="rules" label-width="140px">
        <el-col :span="10">
          <el-form-item :label="$t('topic.PlatformOrderNumber')" prop="orderCode">
            <el-input v-model.trim="creatermaForm.orderCode" :placeholder="$t('title.Afterentering')" @keyup.enter.native="pickOrdersearch()" />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('title.platform')" prop="platFormName">
            <el-input v-model="creatermaForm.platFormName " disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('title.site')" prop="siteCode">
            <el-input v-model="creatermaForm.siteCode" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('title.shopName')" prop="shopCode">
            <el-input v-model="creatermaForm.shopCode" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item :label="$t('topic.RMAType')" prop="returnDetailType">
            <el-radio-group v-model="creatermaForm.returnDetailType">
              <el-radio label="0">{{ $t('topic.Refunds') }}</el-radio>
              <el-radio label="2">{{ $t('topic.OnlyRefund') }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item :label="$t('topic.RMAWay')" prop="returnType">
            <el-radio-group v-model="creatermaForm.returnType">
              <el-radio label="1">{{ $t('topic.Manual') }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-row>
      <h3>{{ $t('title.Refundinformation') }}</h3>
      <el-form ref="creatermaForm" :model="creatermaForm" :rules="rules" label-width="130px">
        <el-col :span="20">
          <el-form-item :label="$t('topic.Typerefund')" prop="refundType">
            <el-radio-group v-model="creatermaForm.refundType" @change="changeRadio">
              <span>
                <el-radio label="1">{{ $t('topic.Fullrefund') }}</el-radio>
                <el-radio label="2">{{ $t('topic.Partialrefund') }}</el-radio>
                <!-- <el-radio label="3">{{ $t('topic.Refuse') }}</el-radio> -->
              </span>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <!--        <el-col :span="10">-->
        <!--          <el-form-item :label="$t('title.Freight')">-->
        <!--            <el-input-->
        <!--              v-if="orderData.itemList && orderData.itemList.length && orderData.itemList[0].shippingPrice"-->
        <!--              v-model="orderData.itemList[0].shippingPrice"-->
        <!--              disabled-->
        <!--            >-->
        <!--              <template #prepend>$</template>-->
        <!--            </el-input>-->
        <!--            <span v-else>{{ $t('title.None') }}</span>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
        <!--        <el-col v-if="orderData.itemList && orderData.itemList.length && orderData.itemList[0].shippingPrice" :span="10">-->
        <!--          <el-form-item label="退还运费">-->
        <!--            <el-checkbox v-model="creatermaForm.returnShipment" :false-label="0" :true-label="1">-->
        <!--              {{ $t('topic.Ticktoshipping') }}-->
        <!--            </el-checkbox>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
      </el-form>
    </el-row>
    <el-row>
      <h3>{{ $t('title.Productinformation') }}</h3>
      <template>
        <el-table
          ref="multipleTable"
          :data="orderData.itemList"
          default-expand-all
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
          />
          <el-table-column
            :label="$t('title.Refundinformation')"
            align="center"
          >
            <template #default="props">
              <div style="display:flex">
                <el-image
                  :src="props.row.mainImage"
                  class="mr-3"
                  style="width: 45px; height: 45px; vertical-align: middle"
                />
                <p style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{ props.row.sku }}</p>
              </div></template>
          </el-table-column>
          <el-table-column
            :label="$t('topic.Numberofrefunds')"
            align="center"
            prop="itemQuantity"
            width="100"
          >
            <template #default="props">
              <p style="display:flex;align-items: center;">
                <el-input
                  v-model="props.row.itemQuantity"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @change="changNumbers(props.$index)"
                  @input="handleChangeInput(props.$index)"
                /></p>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('topic.reasonforreturn')"
            align="center"
            width="200"
          >
            <template #default="props">
              <el-cascader
                v-model="props.row.returnReasonCode"
                :options="returnReasonOptions"
                :props="reasonProps"
                clearable
                filterable
              />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('title.orderamount')"
            width="150"
          >
            <template #default="props">
              <p style="display:flex;align-items: center;"><span> $ </span> <span>{{ props.row.itemPrice }}</span></p>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('title.commodityTax')"
            width="150"
          >
            <template #default="props">
              <p style="display:flex;align-items: center;"><span> $ </span> <span>{{ props.row.itemTax }}</span></p>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('topic.Refundamount')"
            width="150"
          >
            <template #default="props">
              <p style="display:flex;align-items: center;"><span> $ </span> <span>{{ props.row.myItemPrice }}</span></p>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('topic.refunds') + $t('topic.Taxes')"
            width="150"
          >
            <template #default="props">
              <p style="display:flex;align-items: center;"><span> $ </span> <span>{{ props.row.myItemTax }}</span></p>
            </template>
          </el-table-column>
        </el-table>
        <el-form ref="creatermaForm" :model="creatermaForm" label-width="150px" style="margin-top:10px">
          <el-col :span="15">
            <el-form-item :label="$t('title.Buyerpessage')">
              <el-input
                v-model="creatermaForm.sellComments"
                :placeholder="$t('page.inputPlaceholder')"
                :rows="2"
                type="textarea"
              />
            </el-form-item>
          </el-col>
          <el-col :span="15">
            <el-form-item :label="$t('title.stytemNote')">
              <el-input
                v-model="creatermaForm.remark"
                :placeholder="$t('page.inputPlaceholder')"
                :rows="2"
                type="textarea"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :class="$i18n.locale" :label="$t('title.Buyerpicture')">
              <el-upload
                ref="upload"
                :action="action"
                :before-remove="beforeRemoved"
                :file-list="sellerFileList"
                :headers="uploadHeaders"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                list-type="picture-card"
              >
                <i class="el-icon-upload" />
              </el-upload>
              <el-dialog :visible.sync="imgdialogVisible" append-to-body>
                <img :src="dialogImageUrl" alt="" width="100%">
              </el-dialog>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :class="$i18n.locale" :label="$t('title.Attachpicture')">
              <el-upload
                :action="action"
                :before-remove="beforeRemoved1"
                :file-list="brcfilelist"
                :headers="uploadHeaders"
                :on-preview="handlePictureCardPreview1"
                :on-remove="handleRemove1"
                :on-success="handleSuccess1"
                list-type="picture-card"
              >

                <i class="el-icon-upload" />
              </el-upload>
              <el-dialog :visible.sync="imgdialogVisible1">
                <img :src="dialogImageUrl1" alt="" width="100%">
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-form>
      </template>

    </el-row>
    <el-row :span="24" justify="end" style="margin-top: 20px" type="flex">
      <el-col :span="3">
        <el-button @click="goBack">{{ $t('title.back') }}</el-button>
      </el-col>
      <el-col :span="3">
        <el-button :loading="refundLoding" type="primary" @click="saveReturn">{{ $t('title.Save') }}</el-button>
      </el-col>
      <el-col :span="4">
        <el-button :loading="refundLoding" type="primary" @click="submitReturn">{{ $t('title.Release') }}</el-button>
      </el-col>
    </el-row>
    <!-- 物流跟踪号 -->
    <el-dialog
      :title="$t('topic.Agreetoreturn')"
      :visible.sync="buyLableDialogVisible"
      width="60%"
    >
      <el-row :span="24">
        <p />
        <el-form ref="ebayForm" :model="shopifyForm" label-width="130px">
          <el-col :span="15">
            <el-form-item :label="$t('topic.logisticsprovider')">
              <el-input v-model="shopifyForm.returnCarrier" />
            </el-form-item>
          </el-col>
          <el-col :span="15">
            <el-form-item :label="$t('topic.trackingnumber')">
              <el-input v-model="shopifyForm.trackingNumber" oninput="value=value.replace(/[^\a-zA-Z0-9]/g, '')" />
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="buyLableDialogVisible = false">{{ $t('title.cancel') }}</el-button>
        <el-button :loading="saveLoding" type="primary" @click="confirmDefund">{{ $t('page.sure') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import Cookies from 'js-cookie'
import {
  getDetailReturnsInfoById,
  getOmsSelfDeliveryOrderItemTwoLevelGetAmount,
  getReasonCodeByPlatform,
  saveReturnInfos
} from '@/api/omsorder'
import mixin from '@/mixin/oms-mixin'

export default {
  mixins: [mixin],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: () => ({})
    }

  },
  data() {
    return {
      saveLoding: false,
      shopifyForm: {
        trackingNumber: '',
        returnCarrier: ''
      },
      buyLableDialogVisible: false,
      language: '',
      imgdialogVisible1: false,
      refundLoding: false,
      avisible: false,
      disabled: true,
      dialogImageUrl: '',
      dialogImageUrl1: '',
      sellerFileList: [],
      brcfilelist: [],
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      sellerImageUrl: [],
      imageUrl: [],
      returnReasonOptions: [],
      reasonProps: {
        emitPath: false,
        value: 'value',
        label: 'label',
        children: 'childNodes'
      },
      action: process.env.VUE_APP_UPLOAD_API,
      imgdialogVisible: false,
      orderData: [],
      detailList: [],
      rmaId: '',
      flag: true,
      warehouseOptions: [],
      creatermaForm: {
        orderCode: '',
        siteCode: '',
        shopCode: '',
        platFormName: '',
        buyerUsername: '',
        shipperAddress: '',
        returnDetailType: '2',
        returnType: '1',
        refundType: '1',
        refundPoint: true
      },
      ebayForm: {
        trackingNumber: ''
      },
      id: '',
      isShopifySave: 1,
      rules: {
        orderCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        siteCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        shopCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        platFormName: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        buyerUsername: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        shipperAddress: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        returnDetailType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        returnType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        refundType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        returnReasonCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }]
      }
    }
  },
  mounted() {
    const lang = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    const erplang = this.$store.getters.language && this.$store.getters.language === 'en' ? 'en-US' : 'zh-CN'
    this.language = erplang || lang

    this._getDetailReturnsInfoById()
  },
  methods: {
    // 添加选中事件
    changeRadio() {
      this.$forceUpdate()
    },
    // 选中明细数据val
    handleSelectionChange(val) {
      this.detailList = val
      this.detailList.map(e => {
        e.otherRefund = 0
        e.refundAmount = parseFloat(e.itemPrice) - Math.abs(parseFloat(e.promotionDiscount)) + parseFloat(e.otherRefund)
      })
    },

    // 在输入框输入单号后点击回车调接口查询单号对应的数据
    pickOrdersearch() {
      this._getOmsSelfDeliveryOrderItemTwoLevelGetAmount({ orderNumber: this.creatermaForm.orderCode })
    },
    // 点击单号获取对应的数据信息
    async _getOmsSelfDeliveryOrderItemTwoLevelGetAmount(parames) {
      const { datas } = await getOmsSelfDeliveryOrderItemTwoLevelGetAmount(parames)
      if (datas) {
        this.orderData = datas
        this.orderData.itemList.map(e => {
          e.myItemPrice = e.itemPrice
          e.myItemTax = e.itemTax
          e.MyitemQuantity = e.itemQuantity
          e.originalItemQuantity = e.itemQuantity
          e.orderCode = e.orderNumber ? e.orderNumber : e.orderCode
        })
        const { platform, site, merchant, buyerName } = datas
        this.creatermaForm.siteCode = site
        this.creatermaForm.shopCode = merchant
        this.creatermaForm.platFormName = platform
        this.creatermaForm.buyerUsername = buyerName
      }
      this._getAllReasonCode(this.creatermaForm.platFormName)
    },
    // 通过id 查询对应数据
    async _getDetailReturnsInfoById() {
      const { id } = this.$route.query
      if (id) {
        const { id } = this.$route.query
        const { datas } = await getDetailReturnsInfoById(id)
        this.orderData = datas
        this.orderData.itemList = datas.detailList
        this.id = this.orderData.id
        this.rmaId = this.orderData.rmaId
        const { rmaId, returnDetailType, orderCode, returnType, platFormName, shopCode, siteCode, remark, sellComments, buyerUsername, refundPoint } = datas
        this.creatermaForm = { rmaId, returnDetailType: String(returnDetailType), orderCode, remark, returnType: String(returnType), platFormName, shopCode, siteCode, sellComments, buyerUsername, refundPoint }
        if (this.creatermaForm.returnDetailType === '2' && typeof refundPoint === 'undefined') {
          this.creatermaForm.refundPoint = true
        }
        this.orderData.itemList.map(e => {
          this.creatermaForm.refundType = String(e.refundType)
          e.itemQuantity = e.returnNumber
          e.MyitemQuantity = e.originalItemQuantity
          e.orderCode = e.orderNumber ? e.orderNumber : e.orderCode
          e.returnReasonCode = e.returnReasonCodeEN + '@&' + e.returnReasonCode
        })
        this.$nextTick(() => {
          this.orderData.itemList.forEach(i => {
            this.$refs['multipleTable'].toggleRowSelection(i, true)
          })
        })
        this.orderData.imageList.map(e => {
          if (e.imageType === 0) {
            const index = e.imageUrl.lastIndexOf('\/')
            const fileName = e.imageUrl.substring(index + 1, e.imageUrl.length)
            this.sellerFileList.push({
              name: fileName,
              url: e.imageUrl
            })
          } else {
            const index = e.imageUrl.lastIndexOf('\/')
            const fileName = e.imageUrl.substring(index + 1, e.imageUrl.length)
            this.brcfilelist.push({
              name: fileName,
              url: e.imageUrl
            })
          }
        })
        this._getAllReasonCode(this.creatermaForm.platFormName)
      }
    },
    // 获退款退货原因
    async _getAllReasonCode(platform) {
      const lang = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
      const erplang = this.$store.getters.language && this.$store.getters.language === 'en' ? 'en-US' : 'zh-CN'
      const language = erplang || lang
      const isChinese = language === 'zh-CN'
      const { datas } = await getReasonCodeByPlatform(platform)
      this.returnReasonOptions = datas.map(item => {
        item.label = isChinese ? item.reasonCodeFirstCn : item.reasonCodeFirstEn
        item.value = item.reasonCodeFirstEn
        if (item.childNodes && item.childNodes.length > 0) {
          item.childNodes = item.childNodes.map(child => {
            child.label = isChinese ? child.reasonCodeSecondCn : child.reasonCodeSecondEn
            child.value = child.reasonCodeSecondEn + '@&' + child.reasonCodeSecondPlatform
            return child
          })
        }
        return item
      })
    },

    // 保存创建的RMA单
    saveReturn() {
      if (this.detailList.length <= 0) {
        this.$confirm(this.$t('tips.Product'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        return
      } else {
        for (let i = 0; i < this.detailList.length; i++) {
          if (!this.detailList[i].returnReasonCode) {
            this.$message({
              message: '请选择退款原因后保存或发布!',
              type: 'warning'
            })
            return
          }
        }
      }

      const data = {}
      const imgList = []
      this.isRelease = false
      if (this.creatermaForm.refundType === '1' || this.creatermaForm.refundType === '2') {
        // 全额退款/部分退款
        this.$refs['creatermaForm'].validate(async(valid) => {
          if (valid) {
            this.sellerFileList.map(e => {
              imgList.push({
                imageUrl: e.url,
                imageType: 0
              })
            })
            this.brcfilelist.map(e => {
              imgList.push({
                imageUrl: e.url,
                imageType: 1
              })
            })

            const { returnShipment, remark, returnDetailType, orderCode, returnType, siteCode, shopCode, sellComments, platFormName, buyerUsername, shipperAddress } = this.creatermaForm
            this.detailList.map(e => {
              e.returnNumber = e.itemQuantity
              e.refundType = Number(this.creatermaForm.refundType)
            })
            data.detailList = this.detailList.map(e => {
              return {
                ...e,
                returnReasonCodeEN: e.returnReasonCode?.split('@&')?.[0],
                returnReasonCode: e.returnReasonCode?.split('@&')?.[1]
              }
            })
            // [{ 'imageUrl': this.sellerImageUrl, 'imageType': 0 }]
            data.imageList = imgList
            const id = this.id
            const rmaId = this.rmaId
            if (this.id) {
              data.returnsInfo = { returnShipment, rmaId, id, remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
            } else {
              data.returnsInfo = { returnShipment, remark, returnDetailType, orderCode, siteCode, shopCode, returnType, sellComments, platFormName, buyerUsername }
            }
            if (this.creatermaForm.returnDetailType === '2') {
              data.refundPoint = this.creatermaForm.refundPoint
            }
            data.returnsTrack = { shipperAddress, orderCode }
            data.operating = 0
            this._saveReturnInfos(data)
          }
        })
      }
    },
    // 发布
    submitReturn() {
      if (this.detailList.length <= 0) {
        this.$confirm(this.$t('tips.Product'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        return
      } else {
        for (let i = 0; i < this.detailList.length; i++) {
          if (!this.detailList[i].returnReasonCode) {
            this.$message({
              message: '请选择退款原因后保存或发布!',
              type: 'warning'
            })
            return
          }
        }
      }

      const data = {}
      const imgList = []
      this.isRelease = true
      if (this.creatermaForm.returnDetailType === '0') {
        // RMA类型为退货退款打开物流弹窗
        this.buyLableDialogVisible = true
        this.isShopifySave = 1
        return
      }
      if (this.creatermaForm.refundType === '1' || this.creatermaForm.refundType === '2') {
        // 全额退款/部分退款
        this.$refs['creatermaForm'].validate(async(valid) => {
          if (valid) {
            this.sellerFileList.map(e => {
              imgList.push({ imageUrl: e.url,
                imageType: 0 })
            })
            this.brcfilelist.map(e => {
              imgList.push({ imageUrl: e.url,
                imageType: 1 })
            })
            const { returnShipment, remark, returnDetailType, sellComments, orderCode, returnType, siteCode, shopCode, platFormName, buyerUsername, shipperAddress } = this.creatermaForm
            this.detailList.map(e => {
              e.returnNumber = e.itemQuantity
              e.refundType = Number(this.creatermaForm.refundType)
            })
            data.detailList = this.detailList.map(e => {
              return {
                ...e,
                returnReasonCodeEN: e.returnReasonCode?.split('@&')?.[0],
                returnReasonCode: e.returnReasonCode?.split('@&')?.[1]
              }
            })
            data.imageList = imgList
            const id = this.id
            if (this.id) {
              data.returnsInfo = { returnShipment, id, remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
            } else {
              data.returnsInfo = { returnShipment, remark, returnDetailType, orderCode, siteCode, shopCode, returnType, sellComments, platFormName, buyerUsername }
            }
            data.returnsTrack = { shipperAddress, orderCode }
            data.operating = 0
            if (this.creatermaForm.returnDetailType === '2') {
              data.refundPoint = this.creatermaForm.refundPoint
            }
            await this._saveReturnInfos(data)
            // sessionStorage.setItem('indatas', JSON.stringify(data))
            // this.$router.push({
            //   path: '../standwebsite-refunds',
            //   append: true,
            //   query: { 'repostType': 3, ...this.userInfo, refundType: this.creatermaForm.refundType, id: datas }
            // })
          }
        })
      }
    },
    // RMA类型为退货退款时弹出输入跟踪号弹框 确认发布
    confirmDefund() {
      const data = {}
      this.$refs['creatermaForm'].validate(async(valid) => {
        const imgList = []
        if (valid) {
          this.sellerFileList.map(e => {
            imgList.push({ imageUrl: e.url,
              imageType: 0 })
          })
          this.brcfilelist.map(e => {
            imgList.push({ imageUrl: e.url,
              imageType: 1 })
          })
          const { returnShipment, remark, returnDetailType, sellComments, orderCode, returnType, siteCode, shopCode, platFormName, buyerUsername, refundPoint } = this.creatermaForm
          this.detailList.map(e => {
            e.returnNumber = e.itemQuantity
            e.refundType = Number(this.creatermaForm.refundType)
          })
          data.detailList = this.detailList.map(e => {
            return {
              ...e,
              returnReasonCodeEN: e.returnReasonCode?.split('@&')?.[0],
              returnReasonCode: e.returnReasonCode?.split('@&')?.[1]
            }
          })
          data.imageList = imgList
          const id = this.id
          if (this.id) {
            data.returnsInfo = { returnShipment, id, remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
          } else {
            data.returnsInfo = { returnShipment, remark, returnDetailType, orderCode, siteCode, shopCode, returnType, sellComments, platFormName, buyerUsername }
          }
          const { trackingNumber, returnCarrier } = this.shopifyForm

          if (this.isShopifySave === 1) {
            data.returnsTrack = { trackingId: trackingNumber, returnCarrier, shipperAddress: this.creatermaForm.shipperAddress, orderCode }
            data.operating = 0
          } else {
            data.returnsTrack = { trackingId: trackingNumber, returnCarrier }
            data.operating = 1
          }
          if (this.creatermaForm.returnDetailType === '2') {
            data.returnsInfo.refundPoint = refundPoint
          }
          await this._saveReturnInfos(data)
        }
      })
    },
    // 保存或者发布后的返回列表展示页面
    async _saveReturnInfos(data) {
      try {
        this.refundLoding = true
        const { code, msg, datas } = await saveReturnInfos(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          if (!this.isRelease) {
            this.$router.push({
              path: '../../return-manage',
              append: true,
              query: { ...this.userInfo }
            })
          }
          if (this.isRelease) {
            if (this.creatermaForm.refundType === '1' || this.creatermaForm.refundType === '2') {
              this.$router.replace({
                path: '../refunds',
                append: true,
                query: {
                  'repostType': 2,
                  ...this.userInfo,
                  id: datas,
                  refundType: this.creatermaForm.refundType
                }
              })
            }
          }
        }
      } finally {
        this.refundLoding = false
      }
    },

    // 点击已上传的图片时的方法 看大图
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.imgdialogVisible = true
    },
    // 上传图片成功时的方法
    handleSuccess(files, filelist) {
      // 将上传成功的图片放到提交给后台的参数中
      this.sellerFileList.push({
        name: filelist.name,
        url: files.datas
      })
    },
    // 移除图片之前的方法
    beforeRemoved(file) {
      return this.$confirm(`${this.$t('page.SureDelete')} ${file.name}？`)
    },
    // 移除图片之前的方法
    beforeRemoved1(file) {
      return this.$confirm(`${this.$t('page.SureDelete')} ${file.name}？`)
    },
    // 点击已上传的图片时的方法 看大图
    handlePictureCardPreview1(file) {
      this.dialogImageUrl1 = file.url
      this.imgdialogVisible1 = true
    },
    // 上传图片成功时的方法
    handleSuccess1(files, filelist) {
      // 将上传成功的图片放到提交给后台的参数中
      this.brcfilelist.push({
        name: filelist.name,
        url: files.datas
      })
    },
    handleRemove(file, fileList) {
      if (file.status === 'success') {
        this.sellerFileList = fileList
      }
    },
    handleRemove1(file, fileList) {
      if (file.status === 'success') {
        if (file.status === 'success') {
          this.brcfilelist = fileList
        }
      }
    },
    handleChangeInput(index) {
      const data = this.orderData.itemList[index]
      this.$set(this.orderData.itemList, index, data)
    },
    changNumbers(index) {
      const data = this.orderData.itemList[index]
      // 判断订单金额不能大于原本金额
      if (Number(data.itemQuantity) > data.MyitemQuantity) {
        this.$confirm(this.$t('tips.ValueLesser'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.itemQuantity = data.MyitemQuantity
        return
      }
      if (Number(data.itemQuantity) <= 0) {
        this.$confirm(this.$t('tips.GreaterThan0'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.itemQuantity = data.MyitemQuantity
        return
      }
      const rate = Math.floor((Number(data.itemQuantity) / data.MyitemQuantity) * 100)
      if (rate < 100) {
        data.myItemPrice = (data.itemPrice * rate) / 100
        data.myItemPrice = data.myItemPrice.toFixed(2)
        data.myItemTax = (data.itemTax * rate) / 100
        data.myItemTax = data.myItemTax.toFixed(2)
      } else {
        data.myItemPrice = data.itemPrice
        data.myItemTax = data.itemTax
      }
      this.$set(this.orderData.itemList, index, data)
    },
    // 返回
    goBack() {
      this.$router.push({
        path: '../../return-manage',
        append: true,
        query: { ...this.userInfo }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-upload--picture-card {
    width: 75px;
    height: 75px;
    cursor: pointer;
    line-height:75px;
}
.hide .el-upload--picture-card {
  display: none;
}
</style>
